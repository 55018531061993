<template>
  <div class="t-sidebar-menu">
    <t-sidebar no-padding-body>
      <template #header>
        <h4 class="t-sidebar-menu__title">
          {{ title }}
        </h4>
      </template>
      <div class="t-sidebar-menu__items">
        <nuxt-link
          v-for="item in items"
          :key="item.routeName"
          class="t-sidebar-menu__item"
          :to="{ name: item.routeName }"
          exact-active-class="t-sidebar-menu__item--active"
        >
          <div class="t-sidebar-menu__item__icon">
            <t-icon :icon="item.icon" size="lg" />
          </div>
          <div class="t-sidebar-menu__item__text">
            <h6 class="t-sidebar-menu__item__text__primary">
              {{ item.primary }}
            </h6>
            <span v-if="item.secondary" class="t-sidebar-menu__item__text__secondary">
              {{ item.secondary }}
            </span>
          </div>
        </nuxt-link>
      </div>
    </t-sidebar>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.t-sidebar-menu {
  .t-sidebar__header {
    padding: $s-7;
    border-bottom: 1px solid var(--outline-color);
  }

  &__title {
    font-size: $font-size-2xl;
    line-height: $line-height-2xl;
  }

  &__item {
    $self: &;
    padding: $s-7;
    border-bottom: 1px solid var(--outline-color);
    display: flex;
    align-items: center;
    transition: color 0.1s ease-out;

    &__icon {
      padding-right: $s-4;
      color: var(--grey-color);
    }

    &__text {
      &__primary {
        font-size: $font-size-lg;
        line-height: $line-height-lg;
        color: var(--dark-color);
      }

      &__secondary {
        font-size: $font-size-base;
        line-height: $line-height-base;
        color: var(--grey-color);
      }
    }

    &:hover,
    &--active {
      text-decoration: none;

      #{$self} {
        &__icon {
          color: var(--primary-color);
        }

        &__text {
          &__primary {
            color: var(--primary-color);
          }
        }
      }

      &#{$self}--danger {
        #{$self} {
          &__icon {
            color: var(--danger-color);
          }

          &__text {
            &__primary {
              color: var(--danger-color);
            }
          }
        }
      }
    }
  }
}
</style>
