<template>
  <t-body class="account-page">
    <t-sidebar-menu :title="$t('pages.account.account_settings.heading')" :items="items" />
    <nuxt-child />
  </t-body>
</template>

<script>
import Vue from 'vue';
import TSidebarMenu from '~/components/TSidebarMenu';

export default Vue.extend({
  components: { TSidebarMenu },

  computed: {
    items() {
      return [
        {
          primary: this.$t('pages.account.general_settings.heading'),
          secondary: this.$t('pages.account.general_settings.subheading'),
          icon: 'user',
          routeName: this.$RouteEnum.ACCOUNT.GENERAL_INFORMATION,
        },
        {
          primary: this.$t('pages.account.security.heading'),
          secondary: this.$t('pages.account.security.subheading'),
          icon: 'lock',
          routeName: this.$RouteEnum.ACCOUNT.SECURITY,
        },
        {
          primary: this.$t('pages.account.danger_zone.heading'),
          secondary: this.$t('pages.account.danger_zone.subheading'),
          icon: 'trash-alt',
          routeName: this.$RouteEnum.ACCOUNT.DANGER_ZONE,
        },
      ];
    },
  },
});
</script>
<style lang="scss">
.account-page {
  .t-card {
    h4 {
      margin-bottom: $s-7;
    }
  }
}
</style>
